<template>
    <div class="articleContent">
            <!-- 内容 -->
            <div class="acticle_content">
                <div>
                    <!-- 标签 -->
                    <div class="label_content">
                        <a href="javascript:void(0)" @click="switchLabel(value.labelName)" v-for="(value,index) in tabNum">{{value.labelName}}</a>
                    </div>
                    <div class="check_label">
                        <span>您当前选择的标签是:&nbsp;&nbsp;<a>{{params.labelName}}</a></span>
                        <svg class="icon" aria-hidden="true" @click="clearLabel()">
                            <use xlink:href="#icon-quxiao"></use>
                        </svg>
                    </div>
                </div>
                <div :class="[index%2==0?'article animated bounceInLeft':'article animated bounceInRight']" v-for="(value,index) in contents" >
                    <div class="back-img" :style="{backgroundImage: 'url('+value.blogPhoto+')'}">
                        <div class="mask"></div>
                        <a href="javascript:void(0)" @click="JumpBolg(value.id,value.blogTitle)"><span>{{value.labelName}}</span>{{value.blogTitle}}</a>
                        <p>{{value.blogViceTitle}}</p>
                    </div>
                    <div class="titles">
                        <!-- 时间 -->
                        <a href="javascript:void(0)">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-rili"></use>
                            </svg>
                            <span>{{value.releaseDt}}</span>
                        </a>
                        <!-- 评论-->
                        <a href="javascript:void(0)">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-pinglun3"></use>
                            </svg>
                            <span>{{value.comment}}条评论</span>
                        </a>
                        <!-- 浏览量 -->
                        <a href="javascript:void(0)">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-liulan"></use>
                            </svg>
                            <span>{{value.views}}人浏览</span>
                        </a>
                        <!-- 点赞 -->
                        <a href="javascript:void(0)" @click="addlike(value.id)">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-dianzan"></use>
                            </svg>
                            <span>{{value.blogLikes}}人点赞</span>
                        </a>
                        <!-- 阅读全文  -->
                        <a href="javascript:void(0)" class="read" @click="JumpBolg(value.id)">
                            <span>阅读全文</span>
                        </a>
                    </div>
                </div>
                </transition>
                <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        :page-size="5"
                        layout="prev, pager, next"
                        :total="total">
                </el-pagination>
            </div>
        </div>
</template>

<script>
    export default {
        name: "articleContent",
        data(){
            return{
                /* 标签 */
                tabNum:[],
                params:{
                    /* 每页显示行数 */
                    pagesize:5,
                    /* 当前页 */
                    pagenum:1,
                    /* 当前选中的标签 */
                    labelName:this.$store.state.lableName,
                },
                /* 总条数 */
                total:0,
                /* 显示的文章 */
                contents:[]
            }
        },
        methods:{
            /* 当前页发生变化的时候 */
            handleCurrentChange(val){
                this.params.pagenum = val;
                this.blogList()
            },
            /* 获取所有标签 */
            async findLabelAll(){
                const res = await this.$home.findLabelAll()
                this.tabNum = res.data
            },
            /* 跳转文章页 */
            async JumpBolg(value,name){
                this.$store.commit("updatetitleName",name)
                /* 显示轮播图 */
                this.$store.commit("updateRotation",false)
                this.$store.commit("updateBlogId",value)
                await this.$home.addBolgviews({id:value})
                /* 路由跳转 */
                this.$router.push({path:"/blogpostImg/"+value})
            },
            /* 显示当前选中标签 */
            switchLabel(value){
                this.$store.commit("updatelabelName",value)
                this.params.labelName=value
                this.blogList()
            },
            /* 获取文章列表 */
            async blogList(){
                const res = await this.$home.blogList(this.params)
                this.contents = res.data.list
                /* 总行数 */
                this.total = res.data.total
            },
            clearLabel(){
                this.$store.commit("updatelabelName",'')
                this.params.labelName=''
                this.blogList()
            },
            /* 点赞 */
            async addlike(value){
                await this.$home.addlikes({id:value})
                this.blogList()
            },
        },
        created() {
            $("#article").addClass("animated wobble slow ")
            this.blogList()
            this.findLabelAll()
            /* 显示轮播图 */
            this.$store.commit("updateRotation",false)
            /* 隐藏左侧 */
            this.$store.commit("updateLeftShow",true)
        }
    }
</script>

<style scoped>

</style>
